// CONFIGURA O LOADER DO SITE
waitLoaderPage = function(){
    console.log("UX > Carregando Loader...");
    $('.loader-inner').fadeOut('fast',function(){
        $( "#waitLoaderPageAnimate" ).animate({
                "top": "46px",
                easing: "easein"
            }, 500, function() {

            $("#waitLoaderPage").fadeOut("slow",function(){
            });
        });
    });
};

showUserAutenticated = function () {
    var autenticate = $.cookie('autenticate');
    if(autenticate){
        $('.login').hide();
    }
};


isAutenticateLocal = function(){
    console.log("verifyAutenticate ...");
    var autenticate = $.cookie('autenticate');
    if(!autenticate){
       return false;
    }
    return true;
};

logout = function () {
    console.log("logout ...");
    $.removeCookie('autenticate', { path: '/' });
    $('.login').show();

};

saveLocalData = function (msisdn) {
    $.cookie('autenticate', 1, { expires: 60, path: '/' });
    $.cookie('msisdn', msisdn, { expires: 60, path: '/' });
    showUserAutenticated();
};


// ABRE E FECHA MENU MOBILE
$('.open-anchor').on('click touchstart', function(e){
    console.log("UX > Open Navigation");
    $('html, body').addClass('nav-active');
    $('.close-anchor').show();
    $('.mask').show();
    $('#logo').css("display", "none");
    $('.header-top-fly').each(function() {
        $('.header-top-fly').css("display", "none");
    });
    e.preventDefault();
});

$('.close-anchor').on('click touchstart', function(e){
    console.log("UX > Close Navigation");
    $('html, body').removeClass('nav-active');
    $('.close-anchor').hide();
    $('.mask').hide();
    $('#logo').css("display", "block");
    $('.header-top-fly').each(function() {
        $('.header-top-fly').css("display", "block");
    });
    e.preventDefault();
});


// VERIFICA JANELA PARA HABILITAR MENU FIXO DESKTOP
$(document).ready(function() {
    if ( $(window).width() > 1199) {
        console.log("UX > Tela Desktop - Fixar Menu Superior");
        $('.open-anchor').css("display", "none");
        $('.close-anchor').css("display", "none");
        $('#menu-desktop').css("display", "block");
    } else {
        console.log("UX > Tela Mobile - Nada a fixar");
    }
});
var resize = function () {
    if ( $(window).width() > 1199){
        /////Ajusta o tamanho do texto do slider;
        $('.featuredVideoSliderMasking').width(1200);
        $('.featuredVideoSliderContent').width(1200-132);
        console.log("UX > Tela Desktop Resize - Fixar Menu Superior");
        $('.open-anchor').css("display", "none");
        $('.close-anchor').css("display", "none");
        $('#menu-desktop').css("display", "block");
    } else {
        console.log("UX > Tela Mobile Resize - Nada a fixar");
        var width = $(window).width();
        /////Ajusta o tamanho do texto do slider;
        $('.featuredVideoSliderMasking').width(width);
        $('.featuredVideoSliderContent').width(width-22);
        $('.open-anchor').css("display", "block");
        $('.close-anchor').css("display", "block");
        $('#menu-desktop').css("display", "none");
    }
};
resize();

$(window).resize(function(){
    resize();
});


// ABRE E FECHA SUBMENU DO MOBILE
$(".channel-menu").click(function(e){
    height = 'auto';
    if($(this).next().css('display')=='none'){
        height = '100%';
    }
    $('nav ul').css("height",height);
    $(".submenu").toggle("slow").next();
    $(".channel-submenu-icon-close").toggleClass("channel-submenu-icon-open");
    console.log("UX > Abre/Fecha Menu Mobile");
    e.preventDefault();
});


// HABILITA E DESABILITA MENU FLUTUANTE (TOP-FLY)
$(window).scroll(function() {
    var alturaMenu = $('header').height();
    var larguraMain = $('#main').width();
    var marginLeftNeg = -(larguraMain / 2 + 4);
    var scrollTop = $(window).scrollTop();
    var tamPagina = $(document).height();

    if(scrollTop > alturaMenu){
        console.log ("UX > Floating Header: ", scrollTop);
        $('header').addClass('header-top-fly');
        $('#logo').removeClass('logo-full');
        $('#logo').addClass('logo-loader align-logo-top-fly');
        $('.open-anchor').addClass('open-anchor-top-fly');
        $('header').css({'width' : (larguraMain + 4)});
        $('header').css({'margin-left' : marginLeftNeg});
        $('header').animate({top: '0px'});
        $('.open-anchor').css("display", "block");
        $('#menu-desktop').css("display", "none");
        $('.close-anchor').css("display", "block");
    } else {
        console.log("UX > Header Fixed");
        $('header').removeClass('header-top-fly');
        $('#logo').removeClass('logo-loader align-logo-top-fly');
        $('#logo').addClass('logo-full');
        $('.open-anchor').removeClass('open-anchor-top-fly');
        $('header').css({'margin-left' : 0});
        if ($(window).width() > 1199){
            console.log("UX > Header Fixed - Remove Mobile Icon");
            $('.open-anchor').css("display", "none");
            $('.close-anchor').css("display", "none");
            $('#menu-desktop').css("display", "block");
        } else {
            $('#menu-desktop').css("display", "none");
        }
    }
});


// CONFIGURA PLAYER DE VÍDEO
    var tryPlay=0;
    ajustIframeVideo = function () {
        console.log("UX > Configurando Player width: "+$('.pageInternal').width()+' height:'+$('#general').height());
        var widthVideo = $('.pageInternal').width();
        $('#iframeVideo').prop('width',widthVideo+'px').prop('height',Math.floor(widthVideo/1.77)+'px');
        $('#divIframeVideo').show();
    };

    insertIframeVideo = function (url) {
        var ifr=document.getElementById('iframeVideo');
        ajustIframeVideo();
        if (/player.vimeo/.test(url)) {
            ifr.onload=function(){
                console.log('laod the iframe')
            };
            ifr.src=url;
        }else{
            var width   = $('#iframeVideo').width();
            var video   = document.createElement('video');

            video.className="video-js";
            video.id="player";
            video.poster=previewLink;
            video.controls=true;
            video.width=width;
            video.height=width/1.8;
            var mp4 =document.createElement("source");
            mp4.type = "video/mp4";
            mp4.src = url;
            video.appendChild(mp4);
            $('#divIframeVideo').html(video);
            var player = videojs('player');
            // VideoJS event
            player.on("play", myfun);

            function myfun() {
                if (!played) {
                    console.log('play');
                    played = true;
                    addView();
                }
            }
            // Subscription.closeLoader();
        }
    };

    addView = function () {
        $.ajax({
            url: '/video/view/' + getCookie('msisdn') + '/' + 1 + '/' + wishedVideo,
            method: "GET",
            data: {
                '_token': token
            },
            success: function (data) {
                console.log(data);
            },
            error: function () {
                console.log('Erro salvando visualizações do vídeo');
            }
        });
    };


// CARREGA O LOADER DO SITE
$(window).ready(function(){
    waitLoaderPage();
});

function submitBusca() {
    var word = $("#searchright").val();
    if(!word){
        return false;
    }
    $("#formSearch").prop("action","/busca/"+word);
    return true;
}

function submitBuscaPanel() {
    var word = $("#searchInput").val();
    if(!word){
        return false;
    }
    $("#formSearchPanel").prop("action","/busca/"+word);
    return true;
}


$('#clickSearch').click(function() {
    $('#formSearch').submit();
});

$('#clickSearchPanel').click(function() {
    $('#formSearchPanel').submit();
});